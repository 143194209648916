import { useEffect, useState } from 'react';
import axios from 'axios';
import ReactApexChart from 'react-apexcharts';
import Spinner from '../component/widget/Spinner';
import Alert from '../component/widget/alert';
import CarouselProd from '../component/widget/carousel';
import './css/produksi.css';

const Produksi = () => {
  const [prodYtdbyArea, setProdYtdByArea] = useState([])
  const [prodDetail, setProdDetail] = useState([])
  const [prodOverview, setProdOverview] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const d = new Date();
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun',
    'Jul', 'Agst', 'Sep', 'Okt', 'Nov', 'Des'
  ];
  const area = [
    "KMJ",
    "KRH",
    "LHD",
    "LMB",
    "UBL"
  ];
  //get data
  const getData = () => {
    setLoading(true)
    axios.get('https://dbspge.mypge.id/api/?q=OTNrMk02SXpFZmJyNEFyWjdEczV1allyRWtmTUdBRnRLcC9TaENnY0p3RT0=')
      .then(response => {
        setLoading(false);
        setProdYtdByArea(response.data.prodYtdbyArea);
        setProdDetail(response.data.prodDetil);
        setProdOverview(response.data.prodOverview);
      })
      .catch(error => {
        setLoading(false);
        setError(`Error : ${error}`)
        console.error(`Error : ${error}`)
      })
  }

  useEffect(() => {
    getData();
  }, [])


  const radialOpt = {
    colors: ['#00A19D'],
    plotOptions: {
      radialBar: {
        startAngle: -180,
        endAngle: 180,
        hollow: {
          margin: 0,
          size: '50%',
          background: '#EEEEEE',
        },
        dataLabels: {
          name: {
            fontSize: '16px',
            color: undefined,
            offsetY: 120
          },
          value: {
            offsetY: -4,
            fontSize: '26px',
            color: undefined,
            formatter: function (val) {
              return val + " %";
            }
          }
        }
      },
    },
    labels: ['']
  };


  /* const pembangkitSeries = [
    {
      name: 'LMB',
      data: [44, 55, 41, 67, 22, 43, 21, 49]
    }, {
      name: 'UBL',
      data: [13, 23, 20, 8, 13, 27, 33, 12]
    }, {
      name: 'KMJ',
      data: [11, 17, 15, 15, 21, 14, 15, 13]
    },
    {
      name: 'KRH',
      data: [13, 23, 20, 8, 13, 27, 33, 12]
    },
    {
      name: 'LHD',
      data: [13, 23, 20, 8, 13, 27, 33, 12]
    }
  ] */
  const pembangkitSeries = []
  const monthCategories = []

  for (var i = 0; i <= monthNames.length; i++) {
    if (i === d.getMonth()) {
      monthCategories.push(monthNames[i]);
    }
  }

  if (typeof (prodDetail) !== 'undefined') {
    var dataS = [];
    var obj = {}
    area.forEach((a) => {
      dataS[a] = [];
      Object.keys(prodDetail).forEach((idx) => {
        if (prodDetail[idx].code === a) {
          dataS[a].push(Math.floor(prodDetail[idx].percentage))
        }
      })
      obj = {
        name: a,
        data: dataS[a]
      }
      pembangkitSeries.push(obj);

    })
  }

  // console.log(pembangkitSeries);

  const pembangkitOptions = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      stackType: '100%'
    },
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          position: 'bottom',
          offsetX: -10,
          offsetY: 0
        }
      }
    }],
    xaxis: {
      categories: monthCategories,
    },
    fill: {
      opacity: 1
    },
    legend: {
      position: 'right',
      offsetX: 0,
      offsetY: 50
    },
  };
  const forcastSeries = [
    {
      name: 'Rencana Bulanan',
      type: 'column',
      data: [1.4, 2, 2.5, 1.5, 2.5, 2.8, 3.8, 4.6]
    }, {
      name: 'Realisasi Bulanan',
      type: 'column',
      data: [1.1, 3, 3.1, 4, 4.1, 4.9, 6.5, 8.5]
    }, {
      name: 'Prognosa',
      type: 'line',
      data: [20, 29, 37, 36, 44, 45, 50, 58]
    }, {
      name: 'Kumulatif Rencana',
      type: 'column',
      data: [1.1, 3, 3.1, 4, 4.1, 4.9, 6.5, 8.5]
    }, {
      name: 'Kumulatif Realisasi Prognosa',
      type: 'column',
      data: [1.1, 3, 3.1, 4, 4.1, 4.9, 6.5, 8.5]
    },
  ];

  const forecasReports = {
    chart: {
      height: 350,
      type: 'line',
      stacked: false
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: [1, 1, 4]
    },
    title: {
      text: 'Rencana Prognosa Produksi PGE',
      align: 'left',
      offsetX: 110
    },
    xaxis: {
      categories: [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016],
    },
    yaxis: [
      {
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#008FFB'
        },
        labels: {
          style: {
            colors: '#008FFB',
          }
        },
        title: {
          text: "Income (thousand crores)",
          style: {
            color: '#008FFB',
          }
        },
        tooltip: {
          enabled: true
        }
      },
      {
        seriesName: 'LMB',
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#00E396'
        },
        labels: {
          style: {
            colors: '#00E396',
          }
        },
        title: {
          text: "Operating Cashflow (thousand crores)",
          style: {
            color: '#00E396',
          }
        },
      },
      {
        seriesName: 'UBL',
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#FEB019'
        },
        labels: {
          style: {
            colors: '#FEB019',
          },
        },
        title: {
          text: "Revenue (thousand crores)",
          style: {
            color: '#FEB019',
          }
        }
      },
    ],
    tooltip: {
      fixed: {
        enabled: true,
        position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
        offsetY: 30,
        offsetX: 60
      },
    },
    legend: {
      horizontalAlign: 'left',
      offsetX: 40
    },
  }


  //gaug meter chart
  const todaySeries = [];
  const mtdSeries = [];
  const ytdSeries = [];
  if (prodOverview && Object.keys(prodOverview).length > 0) {
    todaySeries.push(Math.floor(prodOverview['day']));
    mtdSeries.push(Math.floor(prodOverview['monthTD']));
    ytdSeries.push(Math.floor(prodOverview['yearTD']));
  }

  const prodOverviewTodayOpt = {
    chart: {
      type: 'radialBar',
      offsetY: -20,
      sparkline: {
        enabled: true
      }
    },
    theme: {
      palette: 'palette6' // upto palette10
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#e7e7e7",
          strokeWidth: '97%',
          margin: 5, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            color: '#999',
            opacity: 1,
            blur: 2
          }
        },
        hollow: {
          margin: 0,
          background: '#EEEEEE',
        },
        dataLabels: {
          name: {
            show: false
          },
          value: {
            offsetY: -4,
            fontSize: '22px',
            formatter: function (val) {
              return val;
            }
          }
        }
      }
    },
    grid: {
      padding: {
        top: -10
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        shadeIntensity: 0.4,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 53, 91]
      }
    },
    labels: ['Average Results'],
  }

  const prodOverviewMtdOpt = {
    chart: {
      type: 'radialBar',
      offsetY: -20,
      sparkline: {
        enabled: true
      }
    },
    theme: {
      palette: 'palette8' // upto palette10
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#e7e7e7",
          strokeWidth: '97%',
          margin: 5, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            color: '#999',
            opacity: 1,
            blur: 2
          }
        },
        hollow: {
          margin: 0,
          background: '#EEEEEE',
        },
        dataLabels: {
          name: {
            show: false
          },
          value: {
            offsetY: -2,
            fontSize: '22px',
            formatter: function (val) {
              return val;
            }
          }
        }
      }
    },
    grid: {
      padding: {
        top: -10
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        shadeIntensity: 0.4,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 53, 91]
      }
    },
    labels: ['Average Results'],
  }

  const prodOverviewYtdOpt = {
    chart: {
      type: 'radialBar',
      offsetY: -20,
      sparkline: {
        enabled: true
      }
    },
    theme: {
      palette: 'palette7' // upto palette10
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#e7e7e7",
          strokeWidth: '97%',
          margin: 5, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            color: '#999',
            opacity: 1,
            blur: 2
          }
        },
        hollow: {
          margin: 0,
          background: '#EEEEEE',
        },
        dataLabels: {
          name: {
            show: false
          },
          value: {
            offsetY: -2,
            fontSize: '22px',
            formatter: function (val) {
              return val;
            }
          }
        }
      }
    },
    grid: {
      padding: {
        top: -10
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        shadeIntensity: 0.4,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 53, 91]
      }
    },
    labels: ['Average Results'],
  }

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          {/* start title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">Produksi</h4>

                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <a href="#!">Dashboard</a>
                    </li>
                    <li className="breadcrumb-item active">Produksi</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          {/* end title */}

          <div className="row">
            <div className="col-xl-7">
              {/* start Prod Update */}
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title mb-0">Production Update</h4>
                </div>
                <div className="card-body" >
                  <div className="row">
                    <div className="col-12" >
                      {loading ? <Spinner /> : error ? <Alert message={error} type={'danger'} /> : typeof (prodYtdbyArea) === 'undefined' ? <Alert message={'No Data'} type={'warning'} /> : <CarouselProd area={prodYtdbyArea} />}
                    </div>
                    <h5 className='mt-4'>Production YTD vs Plan</h5>
                    {loading ? <Spinner /> : error ? <Alert message={error} type={'danger'} /> : typeof (prodYtdbyArea) === 'undefined' ? <Alert message={'No Data'} type={'warning'} /> : prodYtdbyArea.map(item => {
                      return (
                        <div key={item.code} className="col">
                          <ReactApexChart options={radialOpt} series={[Math.floor(item.percentage)]} type="radialBar" height={200} />
                          <h5 className="text-center">{item.code}</h5>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
              {/* end Prod Update */}

              {/* start chart Power gen Reports*/}

              <div className="card">
                <div className="card-header">
                  <h5 className="card-title mb-0">PGE Total Production</h5>
                </div>
                <div className="card-body">

                  {loading ? <Spinner /> : error ? <Alert message={error} type={'danger'} /> : typeof (prodOverview) === 'undefined' ? <Alert message={'No Data'} type={'warning'} /> :
                    <div className="row">
                      <div className="col-4">
                        <h5 className="text-center mb-4">Today</h5>
                        <ReactApexChart options={prodOverviewTodayOpt} series={todaySeries} type="radialBar" height={200} />
                      </div>
                      <div className="col-4">
                        <h5 className="text-center mb-4">MTD</h5>
                        <ReactApexChart options={prodOverviewMtdOpt} series={mtdSeries} type="radialBar" height={200} />
                      </div>
                      <div className="col-4">
                        <h5 className="text-center mb-4">YTD</h5>
                        <ReactApexChart options={prodOverviewYtdOpt} series={ytdSeries} type="radialBar" height={200} />
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
            {/* end chart Power gen Reports */}

            <div className="col-xl-5">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title mb-0">
                    Grafik Pembangkitan
                  </h4>
                </div>
                <div className="card-body">
                  {loading ? <Spinner /> : error ? <Alert message={error} type={'danger'} /> : typeof (prodDetail) === 'undefined' ? <Alert message={'No Data'} type={'warning'} /> : <ReactApexChart options={pembangkitOptions} series={pembangkitSeries} type="bar" height={450} />}
                </div>
              </div>



              <div className="card">
                <div className="card-header">
                  <h4 className="card-title mb-0">
                    Forecast Reports
                  </h4>
                </div>
                <div className="card-body">
                  <ReactApexChart options={forecasReports} series={forcastSeries} type="bar" height={450} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">2021 © PGE.</div>
            <div className="col-sm-6">
              <div className="text-sm-end d-none d-sm-block"></div>
            </div>
          </div>
        </div>
      </footer>
    </div >
  );
};


export default Produksi;
