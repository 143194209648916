
import { useEffect } from 'react';
import { MapContainer, Marker, Tooltip } from 'react-leaflet';
import L from 'leaflet';
import { BasemapLayer } from "react-esri-leaflet";
import { useState } from 'react';
import axios from 'axios';

/* const fakeData = [
  {
    name: "KAMOJANG",
    code: 'KMJ',
    location: '107.790539,-7.152598',
    link: []
  },
  {
    name: "LAHENDONG",
    code: 'LHD',
    location: '124.831730,1.305375',
    link: []
  },
  {
    name: "ULU BELU",
    code: 'UBL',
    location: '104.581238,-5.305755',
    link: [
      {
        name: 'UBL LOBBY IN',
        link: 'https://rtsp.me/embed/t33zFfSi/'
      },
      {
        name: 'UBL LOBBY OUT',
        link: 'https://rtsp.me/embed/5dzz8zbN/'
      }
    ]
  },
  {
    name: "KARAHA",
    code: 'KRH',
    location: '108.081329,-7.120619',
    link: [
      {
        name: 'KARAHA GPP',
        link: 'https://rtsp.me/embed/YRADaFrk/'
      },
      {
        name: 'KARAHA GPP IN',
        link: 'https://rtsp.me/embed/dDK5K453/'
      }
    ]
  },
  {
    name: "LUMUT BALAI",
    code: 'LMB',
    location: '103.644719,-4.193464',
    link: []
  }
] */

/* const cctVdata = [
  {
    area:"ULU BELU",
    area_code : "UBL",
    cctv_name:"UBLLOBBYIN",
    location: '103.644719,-4.193464',
    link:"https:\/\/rtsp.me\/embed\/t33zFfSi\/"
  },
  {
    area:"KARAHA",
    area_code : "KRH",
    cctv_name:"UBLLOBBYIN",
    location: '108.081329,-7.120619',
    name:"UBLLOBBYOUT",
    link:"https:\/\/rtsp.me\/embed\/5dzz8zbN\/"
  }
] */


const FieldLocation = () => {

  const [loading, setLoading] = useState(true)
  // const [areaSelect, setAreaSelect] = useState("")
  // const [cctvLink, setCCTVLink] = useState([])
  const [urlFrame, setUrlFrame] = useState("")
  const [cctvData, setCctvData] = useState([])
  const iconMarker = L.icon({
    iconUrl: 'assets/images/cctv.png',
    iconSize: [30, 30],
  })

  const changeFrame = (url) => {
    setLoading(true);
    setUrlFrame(url)
    setLoading(false);
  }

  // const getCCTVLink = (code) => {
  //   setLoading(true)
  //   fakeData.forEach((item) => {
  //     if (item.code === code) {
  //       setAreaSelect(item.name);
  //       setCCTVLink(item.link);
  //       setLoading(false)
  //     }
  //   })
  // }

  useEffect(() => {

    const getFieldLocation = () => {
      setLoading(true)
      axios.get('https://dbspge.mypge.id/api/cctv.php?token=asjlkowinbxHJKKSVASJGmn')
        .then(response => {
          console.log(response.data);
          setLoading(false);
          setCctvData(response.data);
        })
        .catch(error => {
          setLoading(false);
          console.error(`Error : ${error}`)
        })
    }

    getFieldLocation();
  }, [])

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">Field Location</h4>

                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <a href="#!">Dashboard</a>
                    </li>
                    <li className="breadcrumb-item active">Field Location</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className="row">

            <div className="col-12">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body p-0">
                      <MapContainer center={[-4.26, 115.66]} zoom={5} scrollWheelZoom={true}  >
                        <BasemapLayer name="Imagery" />
                        {cctvData.map((item, index) => {
                          const latLong = item.location.split(",");
                          // console.log(latLong)
                          return (
                            <Marker key={index} position={[latLong[1], latLong[0]]} icon={iconMarker} eventHandlers={{ click: () => changeFrame(item.link) }}>
                              <Tooltip direction="top">
                                {"cctv_name" in item ? item.cctv_name : item.name}
                              </Tooltip>
                            </Marker>
                          )
                        })}
                      </MapContainer>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="card">
                    <div className="card-body p-0">
                      <div className="ratio ratio-16x9">
                        {(urlFrame === "") ? <p>Please select cctv area on the left side</p> :
                          loading ? "Loading..." : <iframe src={urlFrame} title="Field CCTV" frameBorder="0" allowFullScreen></iframe>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">2021 © PGE.</div>
            <div className="col-sm-6">
              <div className="text-sm-end d-none d-sm-block"></div>
            </div>
          </div>
        </div>
      </footer>
    </div >
  );
}

export default FieldLocation;