import React from "react";
import { NavLink } from "react-router-dom";

export default function Sidebar() {
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div data-simplebar className="h-100">
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="menu-title" data-key="t-menu">
                Menu
              </li>

              <li>
                <NavLink to="/">
                  <i
                    dangerouslySetInnerHTML={{
                      __html: window.feather.icons.home.toSvg(),
                    }}
                  ></i>
                  <span data-key="t-dashboard">Dashboard</span>
                </NavLink>
              </li>

              <li>
                <NavLink to="/production">
                  <img src="assets/images/icon/Icon-Produksi.png" width="24" alt="icon menu" />
                  <span data-key="t-produksi" style={{ marginLeft: '8px' }}>Produksi</span>
                </NavLink>
              </li>

              <li>
                <NavLink to="/hsse">
                  <img src="assets/images/icon/Icon-HSSE.png" width="24" alt="icon menu" />
                  <span data-key="t-hsse" style={{ marginLeft: '8px' }}>HSSE</span>
                </NavLink>
              </li>

              <li>
                <NavLink to="/finance">
                  <img src="assets/images/icon/Icon-Finance.png" width="24" alt="icon menu" />
                  <span data-key="t-finance" style={{ marginLeft: '8px' }}>Finance</span>
                </NavLink>
              </li>

              <li>
                <NavLink to="/plant_performance">
                  <img src="assets/images/icon/Icon-Plant-Performance.png" width="24" alt="icon menu" />
                  <span data-key="t-plant-performance" style={{ marginLeft: '8px' }}>Plant Performance</span>
                </NavLink>
              </li>

              <li>
                <NavLink to="/field_location">
                  <img src="assets/images/icon/Icon-Field-Location.png" width="24" alt="icon menu" />
                  <span data-key="t-field-location" style={{ marginLeft: '8px' }}>Field Location</span>
                </NavLink>
              </li>

              <li>
                <NavLink to="/sub_surface">
                  <img src="assets/images/icon/Icon-Subsurface.png" width="24" alt="icon menu" />
                  <span data-key="t-sub-surface" style={{ marginLeft: '8px' }}>Sub Surface</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
