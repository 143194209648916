import React from "react";

const NotFound = () => {
    return (
        <React.Fragment>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">

                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="text-center mb-5">
                                        <h1 className="display-1 fw-semibold">4<span className="text-primary mx-2">0</span>4</h1>
                                        <h4 className="text-uppercase">Sorry, page not found</h4>
                                        <div className="mt-5 text-center">
                                            <a className="btn btn-primary waves-effect waves-light" href="/">Back to Dashboard</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-md-10 col-xl-8">
                                    <div>
                                        <img src="assets/images/error-img.png" alt="" className="img-fluid" />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default NotFound;
