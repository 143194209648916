import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';
import Spinner from '../component/widget/Spinner';
import Alert from '../component/widget/alert';
import './css/finance.css';

const Finance = () => {
  const [abi, setAbi] = useState([])
  const [revenueUpdate, setRevenueUpdate] = useState([])
  const [revenueUpdateArea, setrevenueUpdateArea] = useState([])
  const [revenueUpdate3, setRevenueUpdate3] = useState([])
  const [realisasi, setRealisasi] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const area = [
    "KAMOJANG",
    "LAHENDONG",
    "SIBAYAK",
    "ULUBELU",
    "KARAHA",
    "LUMUT BALAI",
    "TOTAL"
  ];
  const bgProgressBar = [
    'bg-success',
    'bg-primary',
    'bg-warning'
  ]
  var i = 0;

  const getData = () => {
    setLoading(true)
    axios.get('https://dbspge.mypge.id/api/finance.php?token=asjlkowinbxHJKKSVASJGmn')
      .then(response => {
        setLoading(false)
        setAbi(response.data.abi)
        setRevenueUpdate(response.data.revenueUpdate)
        setrevenueUpdateArea(response.data.revenueUpdateArea)
        setRealisasi(response.data.realisasi)
        setRevenueUpdate3(response.data.revenueUpdate3)
      })
      .catch(error => {
        setLoading(false);
        setError(`Error : ${error}`)
        console.error(`Error : ${error}`)
      })
  }

  useEffect(() => {
    getData();
  }, [])

  const dataPlan = [];
  const dataPlanYTD = [];
  const dataRevYTD2021 = [];
  const dataRevYTD2020 = [];

  if (Object.keys(realisasi).length > 0) {
    Object.keys(realisasi).forEach((idx) => {
      dataPlan.push(realisasi[idx]['RKAP 2021 REVISI'])
      dataPlanYTD.push(realisasi[idx]['DD RKAP 2021'])
      dataRevYTD2021.push(realisasi[idx]['REAL Ytd 2021'])
      dataRevYTD2020.push(realisasi[idx]['REAL Ytd 2020'])
    })
  }

  const aSeries = [{
    name: 'Plan 2021',
    data: dataPlan
  }, {
    name: 'Plan YTD',
    data: dataPlanYTD
  }, {
    name: 'Rev YTD 2021',
    data: dataRevYTD2021
  }, {
    name: 'Rev YTD 2020',
    data: dataRevYTD2020
  }];

  const aOpt = {
    chart: {
      type: 'bar',
      height: 350
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
        borderRadius: 10,
        dataLabels: {
          position: 'top', // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val;
      },
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: ["#304758"]
      }
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories: area,
    },
    fill: {
      opacity: 1
    },
  };

  // const revSeries = [92.1, 98.6];
  // const opexSeries = [88.5, 84.4];
  // const ebidSeries = [93.2, 88.6];
  const revSeries = [];
  const opexSeries = [];
  const ebidSeries = [];

  if (Object.keys(revenueUpdate).length > 0) {
    Object.keys(revenueUpdate).forEach((idx) => {
      if (revenueUpdate[idx]['NAME'] === 'Revenue vs Plan YTD') {
        revSeries.push(Math.floor(revenueUpdate[idx]['PLAN']))
        revSeries.push(Math.floor(revenueUpdate[idx]['REVENUE']))
      }
      if (revenueUpdate[idx]['NAME'] === 'Opex vs Plan YTD') {
        opexSeries.push(Math.floor(revenueUpdate[idx]['PLAN']))
        opexSeries.push(Math.floor(revenueUpdate[idx]['OPEX']))
      }
      if (revenueUpdate[idx]['NAME'] === 'Ebitda vs Plan YTD') {
        ebidSeries.push(Math.floor(revenueUpdate[idx]['PLAN']))
        ebidSeries.push(Math.floor(revenueUpdate[idx]['EBITDA']))
      }
    })
  }

  const revOpt = {
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: 0,
        endAngle: 270,
        hollow: {
          margin: 5,
          size: '20%',
          background: 'transparent',
          image: undefined,
        },
        dataLabels: {
          show: false,
          name: {
            fontSize: '12px',
          },
          value: {
            fontSize: '14px',
          },
        }
      }
    },
    colors: ['#5584AC', '#22577E'],
    labels: ['Plan', 'Revenue'],
    legend: {
      show: true,
      floating: true,
      fontSize: '16px',
      position: 'left',
      offsetX: -30,
      offsetY: 10,
      labels: {
        useSeriesColors: true,
      },
      markers: {
        size: 0
      },
      formatter: function (seriesName, opts) {
        return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
      },
      itemMargin: {
        vertical: 3
      }
    },
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          show: false
        }
      }
    }]
  };

  const revOpt2 = {
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: 0,
        endAngle: 270,
        hollow: {
          margin: 5,
          size: '20%',
          background: 'transparent',
          image: undefined,
        },
        dataLabels: {
          show: false,
          name: {
            fontSize: '12px',
          },
          value: {
            fontSize: '14px',
          },
        }
      }
    },
    colors: ['#5584AC', '#22577E'],
    labels: ['Plan', 'OPEX'],
    legend: {
      show: true,
      floating: true,
      fontSize: '16px',
      position: 'left',
      offsetX: -35,
      offsetY: 10,
      labels: {
        useSeriesColors: true,
      },
      markers: {
        size: 0
      },
      formatter: function (seriesName, opts) {
        return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
      },
      itemMargin: {
        vertical: 3
      }
    },
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          show: false
        }
      }
    }]
  };


  const revOpt3 = {
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: 0,
        endAngle: 270,
        hollow: {
          margin: 5,
          size: '20%',
          background: 'transparent',
          image: undefined,
        },
        dataLabels: {
          show: false,
          name: {
            fontSize: '12px',
          },
          value: {
            fontSize: '14px',
          },
        }
      }
    },
    colors: ['#5584AC', '#22577E'],
    labels: ['Plan', 'EBITDA'],
    legend: {
      show: true,
      floating: true,
      fontSize: '16px',
      position: 'left',
      offsetX: -35,
      offsetY: 10,
      labels: {
        useSeriesColors: true,
      },
      markers: {
        size: 0
      },
      formatter: function (seriesName, opts) {
        return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
      },
      itemMargin: {
        vertical: 3
      }
    },
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          show: false
        }
      }
    }]
  };

  const dataRev = [];
  const dataOpex = [];

  if (Object.keys(revenueUpdateArea).length > 0) {
    Object.keys(revenueUpdateArea).forEach((idx) => {
      dataRev.push(revenueUpdateArea[idx].REVENUE)
      dataOpex.push(revenueUpdateArea[idx].OPEX)
    })
  }
  // console.log(dataRev);

  const rev2Series = [
    {
      name: 'REVENUE',
      data: dataRev
    },
    {
      name: 'OPEX',
      data: dataOpex
    }
  ];

  const rev2Opt = {
    chart: {
      type: 'bar',
      height: 350
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        dataLabels: {
          position: 'top', // top, center, bottom
        },
        colors: {
          ranges: [{
            from: -100,
            to: -46,
            color: '#F15B46'
          }, {
            from: -45,
            to: 0,
            color: '#FEB019'
          }]
        },
        columnWidth: '80%',
      }
    },
    dataLabels: {
      enabled: true,
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: ["#304758"]
      }
    },
    colors: ['#7267CB', '#009DAE'],
    xaxis: {
      type: 'category',
      categories: ['Kamojang', 'Lahendong', 'Sibayak', 'Ulu Belu', 'Karaha', 'Lumut Balai'],
    }
  };

  const ebitdaSeries = [];
  const rasioSeries = [];
  const profitSeries = [];
  const labaSeries = [];

  if (Object.keys(revenueUpdate3).length > 0) {
    Object.keys(revenueUpdate3).forEach((idx) => {
      if (revenueUpdate3[idx]['NAME'] === "EBITDA Margin") {
        ebitdaSeries.push(revenueUpdate3[idx]['PLAN'])
        ebitdaSeries.push(revenueUpdate3[idx]['ACTUAL'])
      }
      if (revenueUpdate3[idx]['NAME'] === "Rasio Kas") {
        rasioSeries.push(revenueUpdate3[idx]['PLAN'])
        rasioSeries.push(revenueUpdate3[idx]['ACTUAL'])
      }
      if (revenueUpdate3[idx]['NAME'] === "Operating Profit Margin") {
        profitSeries.push(revenueUpdate3[idx]['PLAN'])
        profitSeries.push(revenueUpdate3[idx]['ACTUAL'])
      }
      if (revenueUpdate3[idx]['NAME'] === "Laba Bersih YTD") {
        labaSeries.push(revenueUpdate3[idx]['PLAN'])
        labaSeries.push(revenueUpdate3[idx]['ACTUAL'])
      }
    })
  }

  console.log(ebitdaSeries);

  const donutOpt = {
    plotOptions: {
      pie: {
        startAngle: -140,
        endAngle: 140,
      }
    },
    labels: ['Plan', 'Actual'],
    legend: {
      position: 'top'
    },
    colors: ['#50CB93', '#71EFA3'],
    grid: {
      padding: {
        bottom: -50
      }
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
      }
    }]
  };

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            {/* Start title */}
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">Finance</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <a href="#!">Dashboard</a>
                    </li>
                    <li className="breadcrumb-item active">Finance</li>
                  </ol>
                </div>
              </div>
            </div>
            {/* end title */}
            <div className="col-12">
              <div className="card">
                <div className="card-header"><h5 className="card-title mb-0">Realisasi Pendapatan Usaha</h5></div>
                <div className="card-body">

                  {loading ? <Spinner /> : error ? <Alert message={error} type={'danger'} /> : <ReactApexChart options={aOpt} series={aSeries} type="bar" height={350} />}
                </div>


              </div>
            </div>

            <div className="col-6">
              <div className="card">
                <div className="card-header"><h5 className="card-title mb-0">Revenue Update</h5></div>
                <div className="card-body">
                  {loading ? <Spinner /> : error ? <Alert message={error} type={'danger'} /> : <ReactApexChart options={rev2Opt} series={rev2Series} type="bar" height={350} />}
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="card">
                <div className="card-body">
                  {loading ? <Spinner /> : error ? <Alert message={error} type={'danger'} /> : <div className="row">
                    <div className="col">
                      <h5 className="text-center">Revenue vs Plan YTD</h5>
                      <ReactApexChart options={revOpt} series={revSeries} type="radialBar" height={270} />
                    </div>
                    <div className="col">
                      <h5 className="text-center">Opex vs Plan YTD</h5>
                      <ReactApexChart options={revOpt2} series={opexSeries} type="radialBar" height={270} /></div>
                    <div className="col">
                      <h5 className="text-center">EBITDA vs Plan YTD</h5>
                      <ReactApexChart options={revOpt3} series={ebidSeries} type="radialBar" height={270} /></div>
                  </div>}
                </div>
              </div>
            </div>

            <div className="col-6">
              <div className="card">
                <div className="card-header"><h5 className="card-title mb-0">Anggaran Biaya Investasi (ABI)</h5></div>
                <div className="card-body">
                  {loading ? <Spinner /> : error ? <Alert message={error} type={'danger'} /> : Object.entries(abi).map(([idx, val]) =>
                    <div key={idx}>
                      <h5>{`${idx}`}</h5>
                      <div className="progress animated-progess progress-xl mb-4">
                        <div className={`progress-bar ${bgProgressBar[i++]}`} role="progressbar" style={{ width: `${val}%` }} aria-valuenow={val} aria-valuemin="0" aria-valuemax="100">{`${val}%`}</div>
                      </div>
                    </div>
                  )
                  }
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="card">
                <div className="card-body">
                  {loading ? <Spinner /> : error ? <Alert message={error} type={'danger'} /> : <div className="row text-center justify-content-center">
                    <div className="col-3">
                      <h5>EBITDA Margin</h5>
                      <ReactApexChart options={donutOpt} series={ebitdaSeries} type="donut" height={150} width={180} />
                    </div>
                    <div className="col-3">
                      <h5>Rasio Kas</h5>
                      <ReactApexChart options={donutOpt} series={rasioSeries} type="donut" height={150} width={180} />
                    </div>
                    <div className="col-3">
                      <h5>Operating Profit Margin</h5>
                      <ReactApexChart options={donutOpt} series={profitSeries} type="donut" height={150} width={180} />
                    </div>
                    <div className="col-3">
                      <h5>Laba Bersih YTD</h5>
                      <ReactApexChart options={donutOpt} series={labaSeries} type="donut" height={150} width={180} />
                    </div>
                  </div>
                  }
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">2021 © PGE.</div>
            <div className="col-sm-6">
              <div className="text-sm-end d-none d-sm-block"></div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Finance 