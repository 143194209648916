import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../routes";

export default function Topbar() {
  const [darkMode, setDarkMode] = useState("light");
  const [sideMini, setSideMini] = useState("lg");
  //console.log(darkMode);
  const { state, dispatch } = useContext(AuthContext)

  const username = (state.username !== null) ? state.username : localStorage.getItem('username')

  function darkModeChange() {
    const bodyEl = document.body;
    if (darkMode === "light") {
      bodyEl.setAttribute('data-layout-mode', "dark");
      bodyEl.setAttribute('data-topbar', "dark");
      bodyEl.setAttribute('data-sidebar', "dark");
      setDarkMode("dark");
    } else {
      bodyEl.setAttribute('data-layout-mode', "light");
      bodyEl.setAttribute('data-topbar', "light");
      bodyEl.setAttribute('data-sidebar', "light");
      setDarkMode("light");
    }
  }

  function sideBarChange() {
    const bodyEl = document.body
    if (sideMini === 'lg') {
      setSideMini('sm');
    } else {
      setSideMini('lg');
    }
    bodyEl.setAttribute("data-sidebar-size", sideMini);
  }

  useEffect(() => {
    const bodyEl = document.body;
    bodyEl.setAttribute('data-layout-mode', "light");
    bodyEl.setAttribute('data-topbar', "light");
    bodyEl.setAttribute('data-sidebar', "light");

  }, [])

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-lg">
                  <img src="/Logo-PGE.png" alt="" height="50" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">

                <span className="logo-lg">
                  <img src="/Logo-PGE-Putih.png" alt="" height="50" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              className="btn btn-sm px-3 font-size-16 header-item"
              id="vertical-menu-btn"
              onClick={sideBarChange}
            >
              <i className="fa fa-fw fa-bars"></i>
            </button>
          </div>

          <div className="d-flex">
            <div className="dropdown d-none d-sm-inline-block">
              <button
                type="button"
                className="btn header-item"
                id="mode-setting-btn"
                onClick={darkModeChange}
              >
                {(darkMode === "light") ?
                  <i className="fas fa-moon fa-lg"></i>
                  :
                  <i className="fas fa-sun fa-lg"></i>
                }
              </button>
            </div>
            <div className="dropdown d-inline-block">
              <button type="button" className="btn header-item bg-soft-light border-start border-end" id="page-header-user-dropdown"
                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <img className="rounded-circle header-profile-user" src="assets/images/users/avatar-1.jpg" alt="Header Avatar" />
                <span className="d-none d-xl-inline-block ms-1 fw-medium">{username}</span>
                <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
              </button>
              <div className="dropdown-menu dropdown-menu-end">
                <a className="dropdown-item" href="/login" onClick={() => dispatch({ type: "LOGOUT" })}><i className="mdi mdi-logout font-size-16 align-middle me-1"></i> Logout</a>
              </div>
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
}
