import ReactApexChart from 'react-apexcharts';
import { MapContainer, Marker, Popup, Tooltip } from 'react-leaflet';
import { BasemapLayer } from "react-esri-leaflet";
import { useEffect, useState } from 'react';
import axios from 'axios';
import ReportedCaseChart from '../component/chart/ReportedCaseChart';
import HsseIndicatorChart from '../component/chart/HsseIndicatorChart';
import Spinner from '../component/widget/Spinner';
import Alert from '../component/widget/alert';

const Hsse = () => {
  const [totalTemuan, setTotalTemuan] = useState(null)
  const [openClose, setOpenClose] = useState(null)
  const [unsafeTotal, setUnsafeTotal] = useState(null)
  const [clsrTotal, setClsrTotal] = useState(null)
  const [hazardTotal, setHazardTotal] = useState(null)
  const [jmlKategori, setJmlKategori] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  //get data from API

  const getData = () => {
    setLoading(true)
    axios.get('https://pekapge.net/stats.php')
      .then(response => {
        setLoading(false);
        // console.log(response.data.jmlkategori);
        setJmlKategori(response.data.jmlkategori);
        setTotalTemuan(response.data.totaltemuan);
        setOpenClose(response.data.openclosetotal);
        setUnsafeTotal(response.data.unsafetotal);
        setClsrTotal(response.data.clsrtotal);
        setHazardTotal(response.data.hazardtotal);
      })
      .catch(error => {
        setLoading(false);
        setError(`Error : ${error}`);
        console.error(`Error : ${error}`)
      })
  }

  useEffect(() => {
    getData();
  }, [])
  // console.log(unsafeTotal);

  //reported case chart
  const totalCase = (totalTemuan != null) ? totalTemuan["total"] : 0;
  // delete totalTemuan["total"];

  const categoriesReported = [];
  const dataReported = []
  if (totalTemuan !== null) {
    Object.keys(totalTemuan).forEach(function (key) {
      if (key !== 'total') {
        categoriesReported.push(key);
        dataReported.push(totalTemuan[key]);
      }
    });
  }

  // console.log(totalTemuan);

  const reportSeries = [{
    name: "reported case",
    data: (totalTemuan != null) ? dataReported : []
  }];

  const reportOpt = {
    chart: {
      type: 'bar',
      height: 350
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      }
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: (totalTemuan != null) ? categoriesReported : [],
    }
  };


  //set open close chart
  const seriesPie = [];
  const labelPie = [];
  if (openClose !== null) {
    for (const el of openClose) {
      //console.log(el);
      if (el.status === 'Open' || el.status === 'Close') {
        labelPie.push(el.status)
        seriesPie.push(el.jumlah)
      }
    }
  }


  const pieOpt = {
    chart: {
      width: 380,
      type: 'pie',
    },
    labels: labelPie,
    colors: ['#F90716', '#99A799'],
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        },

      }
    }]
  };

  //cslr chart

  const cslrSeries = [{
    name: 'total',
    data: (clsrTotal != null) ? Object.values(clsrTotal) : []
  }];

  const cslrOpt = {
    chart: {
      type: 'bar',
      height: 350
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      }
    },
    dataLabels: {
      enabled: false
    },
    colors: ['#519259'],
    xaxis: {
      categories: (clsrTotal != null) ? Object.keys(clsrTotal) : [],
    }
  };

  //category charts
  const dataUnsafeTotal = [];
  const seriesUnsafeTotal = []
  if (unsafeTotal != null) {
    for (const [key, value] of Object.entries(unsafeTotal)) {
      dataUnsafeTotal.push(value);
      seriesUnsafeTotal.push(key);
    }
  }

  const catSeries = [{
    name: 'total',
    data: (unsafeTotal != null) ? dataUnsafeTotal : []
  }];

  const catOpt = {
    chart: {
      type: 'bar',
      height: 350
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      }
    },
    dataLabels: {
      enabled: false
    },
    colors: ['#516BEB'],
    xaxis: {
      categories: (unsafeTotal != null) ? seriesUnsafeTotal : [],
    }
  };

  //hazard charts
  const hazardSeries = [{
    name: 'total',
    data: (hazardTotal != null) ? Object.values(hazardTotal) : []
  }];

  const hazardOpt = {
    chart: {
      type: 'bar',
      height: 350
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      }
    },
    dataLabels: {
      enabled: false
    },
    colors: ['#F0BB62'],
    xaxis: {
      categories: (hazardTotal != null) ? Object.keys(hazardTotal) : [],
    }
  };

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          {/* start title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">HSSE</h4>

                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <a href="#!">Dashboard</a>
                    </li>
                    <li className="breadcrumb-item active">HSSE</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          {/* end title */}

          <div className="row">
            <div className="col-xl-5">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title mb-0">HSSE Indicator</h4>
                </div>
                <div className="card-body">
                  {
                    loading ?
                      <Spinner />
                      :
                      <HsseIndicatorChart kategori={jmlKategori} />
                  }
                </div>
              </div>

              <div className="card">
                <div className="card-header"><h4 className="card-title mb-0">Reported Case</h4></div>
                <div className="card-body">
                  {loading ? <Spinner /> : error ? <Alert message={error} type={'danger'} /> : <ReportedCaseChart options={reportOpt} series={reportSeries} totalCase={totalCase} average={5} />}
                </div>
              </div>

              <div className="card">
                <div className="card-header">
                  <h4 className="card-title mb-0">Closed VS IN Progress Case</h4>
                </div>
                <div className="card-body">
                  {loading ? <Spinner /> : error ? <Alert message={error} type={'danger'} /> : <ReactApexChart options={pieOpt} series={seriesPie} type="pie" width={380} />}
                </div>
              </div>

            </div>
            <div className="col-xl-7">
              <div className="card">
                <div className="card-header"><h4 className="card-title">Site Map By Report Case</h4></div>
                <div className="card-body">
                  <MapContainer center={[-4.26, 115.66]} zoom={5} scrollWheelZoom={false}>
                    <BasemapLayer name="Imagery" />
                    <Marker position={[-4.26, 115.66]}>
                      <Tooltip permanent>
                        Marker 1
                      </Tooltip>
                      <Popup>
                        Marker 1
                      </Popup>
                    </Marker>
                    <Marker position={[-8.16799, 106.72119]}>
                      <Tooltip permanent>
                        Marker 2
                      </Tooltip>
                      <Popup>
                        Marker 2
                      </Popup>
                    </Marker>
                  </MapContainer>
                </div>
              </div>

              <div className="card">
                <div className="card-body">
                  <ul className="nav nav-pills nav-justified" role="tablist">
                    <li className="nav-item">
                      <a className="nav-link active" data-bs-toggle="tab" href="#category" role="tab" aria-selected="true">
                        <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        <span className="d-none d-sm-block">CATEGORY STATISTIC</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" data-bs-toggle="tab" href="#cslr" role="tab" aria-selected="false">
                        <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                        <span className="d-none d-sm-block">CSLR UPDATE</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" data-bs-toggle="tab" href="#hazard" role="tab" aria-selected="false">
                        <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                        <span className="d-none d-sm-block">HAZARD</span>
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content p-3 text-muted">
                    <div className="tab-pane active" id="category" role="tabpanel">
                      {loading ? <Spinner /> : error ? <Alert message={error} type={'danger'} /> : <ReactApexChart options={catOpt} series={catSeries} type="bar" height={350} />}
                    </div>
                    <div className="tab-pane" id="cslr" role="tabpanel">
                      {loading ? <Spinner /> : error ? <Alert message={error} type={'danger'} /> : <ReactApexChart options={cslrOpt} series={cslrSeries} type="bar" height={350 + Math.floor(Math.random() * 2) + 1} />}
                    </div>

                    <div className="tab-pane" id="hazard" role="tabpanel">
                      {loading ? <Spinner /> : error ? <Alert message={error} type={'danger'} /> : <ReactApexChart options={hazardOpt} series={hazardSeries} type="bar" height={360 + Math.floor(Math.random() * 2) + 1} />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">2021 © PGE.</div>
            <div className="col-sm-6">
              <div className="text-sm-end d-none d-sm-block"></div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}


export default Hsse;
