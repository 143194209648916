import React from 'react'

const HsseIndicatorChart = (props) => {
    const styleBgHsse = {
        backgroundImage: 'url(assets/images/Segitiga-HSSE.png',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    }

    const jmlKategori = props.kategori;

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-4 px-0">
                    <div className="table-responsive">
                        <table className="table">
                            <tbody>
                                <tr>
                                    <td style={{ fontSize: '20px', padding: '3px', fontWeight: 'bold' }}>Fatality</td>
                                </tr>
                                <tr>
                                    <td style={{ fontSize: '20px', padding: '3px', fontWeight: 'bold' }}>Lost Time Injury</td>
                                </tr>
                                <tr>
                                    <td style={{ fontSize: '20px', padding: '3px', fontWeight: 'bold' }}>Restricted Work Case</td>
                                </tr>
                                <tr>
                                    <td style={{ fontSize: '20px', padding: '3px', fontWeight: 'bold' }}>Medical Treatment Case</td>
                                </tr>
                                <tr>
                                    <td style={{ fontSize: '20px', padding: '3px', fontWeight: 'bold' }}>First Aid</td>
                                </tr>
                                <tr>
                                    <td style={{ fontSize: '20px', padding: '3px', fontWeight: 'bold' }}>Near Miss</td>
                                </tr>
                                <tr>
                                    <td style={{ fontSize: '20px', padding: '3px', fontWeight: 'bold' }}>UA/UC</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-8 px-0">
                    <table className="table text-center table-borderless" style={styleBgHsse}>
                        <tbody>
                            <tr>
                                <td style={{ fontSize: '21px', padding: '4px', color: '#000' }}>{(jmlKategori != null) ? jmlKategori["Fatality"] : 0}</td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: '21px', padding: '4px', color: '#000' }}>{(jmlKategori != null) ? jmlKategori["Lost Time Injury"] : 0}</td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: '21px', padding: '4px', color: '#000' }}>{(jmlKategori != null) ? jmlKategori["Restricted Work Case"] : 0}</td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: '21px', padding: '4px', color: '#000' }}>{(jmlKategori != null) ? jmlKategori["Medical Treatment Case"] : 0}</td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: '21px', padding: '5px', color: '#000' }}>{(jmlKategori != null) ? jmlKategori["First Aid"] : 0}</td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: '21px', padding: '5px', color: '#000' }}>{(jmlKategori != null) ? jmlKategori["Near Miss"] : 0}</td>
                            </tr>
                            <tr>
                                <td style={{ fontSize: '21px', padding: '5px', color: '#000' }}>{(jmlKategori != null) ? jmlKategori["UA/UC"] : 0}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    )
}

export default HsseIndicatorChart
