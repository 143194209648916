import React from 'react'
import ReactApexChart from 'react-apexcharts';

const ReportedCaseChart = (props) => {
    return (
        <React.Fragment>
            <ReactApexChart options={props.options} series={props.series} type="bar" height={350} />

            <div className="row text-center">
                <div className="col">
                    <h3>Total Case</h3>
                    <h1>{props.totalCase}</h1>
                </div>
                <div className="col">
                    <h3>Average Response Day</h3>
                    <h1>{props.average}</h1>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ReportedCaseChart;
