import ReactApexChart from 'react-apexcharts';
import { MapContainer, Marker, Popup, Tooltip } from 'react-leaflet';
import { BasemapLayer } from "react-esri-leaflet";
import axios from 'axios';
import { useEffect, useState } from 'react';
import HsseIndicatorChart from '../component/chart/HsseIndicatorChart';
import Spinner from '../component/widget/Spinner';
import Moment from 'react-moment';
import Alert from '../component/widget/alert';


const Dashboard = () => {
  const [jmlKategori, setJmlKategori] = useState(null)
  const [prodYtdbyArea, setProdYtdByArea] = useState([])
  const [genDate, setgenDate] = useState("")
  const [prodYtdbyMonth, setProdYtdbyMonth] = useState([])
  const [revenueUpdate, setRevenueUpdate] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const getData = () => {
    setLoading(true)

    const callHSSEAPI = axios.get('https://pekapge.net/stats.php');
    const callProdAPI = axios.get('https://dbspge.mypge.id/api/?q=OTNrMk02SXpFZmJyNEFyWjdEczV1allyRWtmTUdBRnRLcC9TaENnY0p3RT0=');
    const callFinanceAPI = axios.get('https://dbspge.mypge.id/api/finance.php?token=asjlkowinbxHJKKSVASJGmn');

    axios.all([callFinanceAPI, callHSSEAPI, callProdAPI]).then(
      axios.spread((fetchFinance, fetchHSSE, fetchProd) => {
        //set Finance
        setRevenueUpdate(fetchFinance.data.revenueUpdate)
        //set PRod
        setProdYtdByArea(fetchProd.data.prodYtdbyArea);
        setgenDate(fetchProd.data.generated);
        setProdYtdbyMonth(fetchProd.data.prodYtdbyMonth);
        //set HSSE
        setJmlKategori(fetchHSSE.data.jmlkategori);
        setLoading(false);
      })
    )
      .catch(error => {
        setLoading(false);
        setError(`Error : ${error}`);
        console.error(`Error : ${error}`)
      })

  }

  useEffect(() => {
    getData();
  }, [])

  // const revSeries = [92.1, 98.6];
  // const opexSeries = [88.5, 84.4];
  // const ebidSeries = [93.2, 88.6];

  const revSeries = [];
  const opexSeries = [];
  const ebidSeries = [];

  if (Object.keys(revenueUpdate).length > 0) {
    Object.keys(revenueUpdate).forEach((idx) => {
      if (revenueUpdate[idx]['NAME'] === 'Revenue vs Plan YTD') {
        revSeries.push(Math.floor(revenueUpdate[idx]['PLAN']))
        revSeries.push(Math.floor(revenueUpdate[idx]['REVENUE']))
      }
      if (revenueUpdate[idx]['NAME'] === 'Opex vs Plan YTD') {
        opexSeries.push(Math.floor(revenueUpdate[idx]['PLAN']))
        opexSeries.push(Math.floor(revenueUpdate[idx]['OPEX']))
      }
      if (revenueUpdate[idx]['NAME'] === 'Ebitda vs Plan YTD') {
        ebidSeries.push(Math.floor(revenueUpdate[idx]['PLAN']))
        ebidSeries.push(Math.floor(revenueUpdate[idx]['EBITDA']))
      }
    })
  }

  const revOpt = {
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: 0,
        endAngle: 270,
        hollow: {
          margin: 5,
          size: '20%',
          background: 'transparent',
          image: undefined,
        },
        dataLabels: {
          show: false,
          name: {
            fontSize: '12px',
          },
          value: {
            fontSize: '14px',
          },
        }
      }
    },
    colors: ['#5584AC', '#22577E'],
    labels: ['Plan', 'Revenue'],
    legend: {
      show: true,
      floating: true,
      fontSize: '16px',
      position: 'left',
      offsetX: -35,
      offsetY: 10,
      labels: {
        useSeriesColors: true,
      },
      markers: {
        size: 0
      },
      formatter: function (seriesName, opts) {
        return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
      },
      itemMargin: {
        vertical: 3
      }
    },
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          show: false
        }
      }
    }]
  };

  const revOpt2 = {
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: 0,
        endAngle: 270,
        hollow: {
          margin: 5,
          size: '20%',
          background: 'transparent',
          image: undefined,
        },
        dataLabels: {
          show: false,
          name: {
            fontSize: '12px',
          },
          value: {
            fontSize: '14px',
          },
        }
      }
    },
    colors: ['#5584AC', '#22577E'],
    labels: ['Plan', 'OPEX'],
    legend: {
      show: true,
      floating: true,
      fontSize: '16px',
      position: 'left',
      offsetX: -35,
      offsetY: 10,
      labels: {
        useSeriesColors: true,
      },
      markers: {
        size: 0
      },
      formatter: function (seriesName, opts) {
        return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
      },
      itemMargin: {
        vertical: 3
      }
    },
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          show: false
        }
      }
    }]
  };


  const revOpt3 = {
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: 0,
        endAngle: 270,
        hollow: {
          margin: 5,
          size: '20%',
          background: 'transparent',
          image: undefined,
        },
        dataLabels: {
          show: false,
          name: {
            fontSize: '12px',
          },
          value: {
            fontSize: '14px',
          },
        }
      }
    },
    colors: ['#5584AC', '#22577E'],
    labels: ['Plan', 'EBITDA'],
    legend: {
      show: true,
      floating: true,
      fontSize: '16px',
      position: 'left',
      offsetX: -35,
      offsetY: 10,
      labels: {
        useSeriesColors: true,
      },
      markers: {
        size: 0
      },
      formatter: function (seriesName, opts) {
        return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
      },
      itemMargin: {
        vertical: 3
      }
    },
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          show: false
        }
      }
    }]
  };


  // const prodSeries = [
  //   {
  //     name: "Label 1",
  //     data: [45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10]
  //   },
  //   {
  //     name: "Label 2",
  //     data: [35, 41, 62, 42, 13, 18, 29, 37, 36, 51, 32, 35]
  //   }
  // ];
  const dataActual = [];
  const dataPlan = [];
  const dataMonth = [];
  if (prodYtdbyMonth && Object.keys(prodYtdbyMonth).length > 0) {
    Object.entries(prodYtdbyMonth).forEach(([idx, val]) => {
      // console.log(val);
      dataMonth.push(val.month);
      dataActual.push(Math.floor(val.actual))
      dataPlan.push(Math.floor(val.plan))
    })
  }

  // console.log(dataActual);

  const prodSeries = [
    {
      name: "Actual",
      data: dataActual
    },
    {
      name: "Plan",
      data: dataPlan
    }
  ];

  const prodOptions = {
    chart: {
      height: 350,
      type: 'line',
      toolbar: {
        show: false
      }
    },
    colors: ['#2196f3', '#fa3434'],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth'
    },
    markers: {
      size: 1
    },
    xaxis: {
      categories: dataMonth,//['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Des'],
      title: {
        text: 'Month'
      }
    }
  };

  // const radialSeries = [55]
  const radialOpt = {
    colors: ['#00A19D'],
    plotOptions: {
      radialBar: {
        startAngle: -180,
        endAngle: 180,
        hollow: {
          margin: 0,
          size: '50%',
          background: '#EEEEEE',
        },
        dataLabels: {
          name: {
            fontSize: '16px',
            color: undefined,
            offsetY: 120
          },
          value: {
            offsetY: -4,
            fontSize: '26px',
            color: undefined,
            formatter: function (val) {
              return val + "%";
            }
          }
        }
      },
    },
    labels: [' '],
  };

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          {/* start title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">Dashboard</h4>

                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <a href="#!">Dashboard</a>
                    </li>
                    <li className="breadcrumb-item active">Dashboard</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          {/* end title */}

          <div className="row justify-content-center">
            <div className="col">
              <h6 className="float-end">Last Update Data : <Moment format="D-M-Y">{genDate}</Moment></h6>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-5">
              {/* start chart revenue */}
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title mb-0">Revenue Update</h4>
                </div>
                <div className="card-body">
                  {loading ? <Spinner /> : error ? <Alert message={error} type={'danger'} /> : <div className="row">
                    <div className="col-4">
                      <h5 className="text-center">Revenue vs Plan YTD</h5>
                      <ReactApexChart options={revOpt} series={revSeries} type="radialBar" height={220} />
                    </div>
                    <div className="col-4">
                      <h5 className="text-center">Opex vs Plan YTD</h5>
                      <ReactApexChart options={revOpt2} series={opexSeries} type="radialBar" height={220} /></div>
                    <div className="col-4">
                      <h5 className="text-center">EBITDA vs Plan YTD</h5>
                      <ReactApexChart options={revOpt3} series={ebidSeries} type="radialBar" height={220} /></div>
                  </div>}
                </div>
              </div>
              {/* end chart revenue */}

              {/* start chart HSSE Indicator */}

              <div className="card">
                <div className="card-header">
                  <h4 className="card-title mb-0">HSSE Indicator</h4>
                </div>
                <div className="card-body">
                  {
                    loading ?
                      <Spinner />
                      :
                      <HsseIndicatorChart kategori={jmlKategori} />
                  }
                </div>
              </div>
              {/* end chart HSSE */}

              {/* start chart production */}
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title mb-0">Production Prognosis</h4>
                </div>
                <div className="card-body">
                  {loading ? <Spinner /> : error ? <Alert message={error} type={'danger'} /> : typeof (prodYtdbyMonth) === 'undefined' ? <Alert message={'No Data'} type={'warning'} /> : <ReactApexChart options={prodOptions} series={prodSeries} type="line" height={350} />}
                </div>
              </div>
              {/* end chart production */}
            </div>

            <div className="col-xl-7">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title mb-0">
                    Production Update
                  </h4>
                </div>
                <div className="card-body">

                  <div className="row">
                    <div className="col-12">
                      {loading ? <Spinner /> : error ? <Alert message={error} type={'danger'} /> :
                        typeof (prodYtdbyArea) === 'undefined' ? <Alert message={'No Data'} type={'warning'} /> : <MapContainer center={[-4.26, 115.66]} zoom={5} scrollWheelZoom={false}>
                          <BasemapLayer name="Imagery" />
                          {prodYtdbyArea.map(item => {
                            const latLong = item.location.split(",");
                            return (
                              <Marker key={item.code} position={[latLong[1], latLong[0]]}>
                                <Tooltip permanent>
                                  {`${item.code} - ${Math.floor(item.percentage)}%`}
                                </Tooltip>
                                <Popup>
                                  {`Area : ${item.area}`}<br />
                                  {`Actual : ${item.actual}`}<br />
                                  {`Plan : ${item.plan}`}
                                </Popup>
                              </Marker>
                            )
                          })}
                        </MapContainer>
                      }
                    </div>
                    <h5 className='mt-4'>Production YTD vs Plan</h5>
                    {loading ? <Spinner /> : error ? <Alert message={error} type={'danger'} /> : typeof (prodYtdbyArea) === 'undefined' ? <Alert message={'No Data'} type={'warning'} /> : prodYtdbyArea.map(item => {
                      return (
                        <div key={item.code} className="col">
                          <ReactApexChart options={radialOpt} series={[Math.floor(item.percentage)]} type="radialBar" height={200} />
                          <h5 className="text-center">{item.code}</h5>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">2021 © PGE.</div>
            <div className="col-sm-6">
              <div className="text-sm-end d-none d-sm-block"></div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Dashboard;
