import React, { useContext, useState } from 'react'
import { AuthContext } from '../routes'

const Login = () => {
    const { dispatch } = useContext(AuthContext)

    const initialState = {
        username: "",
        password: "",
        remember: false,
        isSubmit: false,
        errorMessage: null,
    }

    const [data, setData] = useState(initialState)
    const [passwordShown, setPasswordShown] = useState(false);

    const handleInputChange = e => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }
    // Password toggle handler
    const togglePassword = () => {
        // When the handler is invoked
        // inverse the boolean state of passwordShown
        setPasswordShown(!passwordShown);
    };


    const handleSubmit = (e) => {
        e.preventDefault()

        setData({
            ...data,
            isSubmit: true,
            errorMessage: null
        })

        if (process.env.REACT_APP_USERNAME === data.username && process.env.REACT_APP_PASSWORD === data.password) {
            let token = (Math.random() + 1).toString(30).substring(5)
            dispatch({
                type: "LOGIN",
                payload: { username: data.username, remember: data.remember, token: token }
            })
        } else {
            setData({
                ...data,
                isSubmit: false,
                errorMessage: 'Invalid username and password'
            })
        }
    }

    return (
        <div className="auth-page">
            <div className="container-fluid p-0">
                <div className="row g-0">
                    <div className="col-xxl-9 col-lg-8 col-md-7">
                        <div className='auth-bg pt-md-5 p-4 d-flex'></div>
                    </div>
                    <div className="col-xxl-3 col-lg-4 col-md-5">
                        <div className="auth-full-page-content d-flex p-sm-5 p-4">
                            <div className="w-100">
                                <div className="d-flex flex-column h-100">
                                    <div className="mb-4 mb-md-5 text-center">
                                        <a href="#logo" className="d-block auth-logo">
                                            <img src="/Logo-PGE.png" alt="PGE" height="50" />
                                        </a>
                                    </div>
                                    <div className="auth-content my-auto">
                                        <div className="text-center">
                                            <h5 className="mb-0">Welcome Back !</h5>
                                            <p className="text-muted mt-2">Sign in to continue to PGE Dashboard.</p>
                                        </div>
                                        <form className="mt-4 pt-2" onSubmit={handleSubmit}>
                                            {data.errorMessage && (
                                                <div className="alert alert-danger alert-dismissible">{data.errorMessage}
                                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                                </div>

                                            )}
                                            <div className="mb-3">
                                                <label className="form-label">Username</label>
                                                <input type="text" className="form-control" name="username" onChange={handleInputChange} id="username" placeholder="Enter username" value={data.username} />
                                            </div>
                                            <div className="mb-3">
                                                <div className="d-flex align-items-start">
                                                    <div className="flex-grow-1">
                                                        <label className="form-label">Password</label>
                                                    </div>
                                                </div>
                                                <div className="input-group auth-pass-inputgroup">
                                                    <input type={passwordShown ? "text" : "password"} className="form-control" name="password" onChange={handleInputChange} placeholder="Enter password" aria-label="Password" aria-describedby="password-addon" value={data.password} />
                                                    <button className="btn btn-light shadow-none ms-0" onClick={togglePassword} type="button" id="password-addon"><i className="mdi mdi-eye-outline"></i></button>
                                                </div>
                                            </div>
                                            <div className="row mb-4">
                                                <div className="col">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="remember" onChange={handleInputChange} value="true" id="remember-check" />
                                                        <label className="form-check-label" htmlFor="remember-check">
                                                            Remember me
                                                        </label>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="mb-3">
                                                <button className="btn btn-primary w-100 waves-effect waves-light" type="submit" disabled={data.isSubmit}>{data.isSubmit ? "Loading..." : "Log In"}</button>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="mt-4 mt-md-5 text-center">
                                        <p className="mb-0">© {new Date().getFullYear()} PGE . Crafted with <i className="mdi mdi-heart text-danger"></i></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}
export default Login 
