import React from "react";

export default function SubSurface() {
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">Sub Surface</h4>

                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <a href="#!">Dashboard</a>
                    </li>
                    <li className="breadcrumb-item active">Sub Surface</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="ratio ratio-16x9">
                <iframe src="https://arcgis.mypge.id/portal/apps/webappviewer/index.html?id=29746ef064b24e94b97e265c5b958600" title="Sub Surface" frameBorder="0" allowFullScreen></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">2021 © PGE.</div>
            <div className="col-sm-6">
              <div className="text-sm-end d-none d-sm-block"></div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
