import Router from "./routes";

function App() {

  return (
    <div className="layout-wrapper" >
      <Router />
    </div>
  );
}

export default App;