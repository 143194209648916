import React from 'react'

const CarouselProd = ({ area }) => {
    return (
        <React.Fragment>
            <div id="carouselExampleCaption" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner" role="listbox">
                    {area.map((item, i) => {
                        return (
                            <div key={i} className={`carousel-item ${i === 0 ? "active" : ""}`}>
                                <img src={`assets/images/area/${item.area}.jpg`} alt={item.code} className="d-block img-fluid mx-auto" />
                                <div className="carousel-caption text-white-50">
                                    <h4 className="text-white">{item.area}</h4>
                                    <div className="row row-cols-4">
                                        <div className="col text-white font-size-17">Capacity: {item.mb}MW</div>
                                        <div className="col text-white font-size-17">Actual YTD: {Math.floor(item.actual)}</div>
                                        <div className="col text-white font-size-17">Plan YTD: {item.plan}</div>
                                        <div className="col text-white font-size-17">Daily CF: xxx</div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                    }
                </div>
                <a className="carousel-control-prev" href="#carouselExampleCaption" role="button" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#carouselExampleCaption" role="button" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                </a>
            </div>
        </React.Fragment>
    )
}

export default CarouselProd;
