import { useReducer, createContext } from "react";
import { BrowserRouter as Routers, Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import FieldLocation from "../pages/FieldLocation";
import Finance from "../pages/Finance";
import Hsse from "../pages/Hsse";
import PlantPerform from "../pages/PlantPerform";
import Produksi from "../pages/Produksi";
import NotFound from "../pages/NotFound";
import SubSurface from "../pages/SubSurface";
import Sidebar from "../component/layout/sidebar";
import Topbar from "../component/layout/topbar";
import Login from "../pages/Login";

//context
export const AuthContext = createContext()

//intial state 
const initialState = {
  isAuthenticated: false,
  username: null,
  remember: false,
  token: '',
}

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      localStorage.setItem('username', action.payload.username)
      localStorage.setItem('remember', action.payload.remember)
      localStorage.setItem('token', action.payload.token)
      return {
        ...state,
        isAuthenticated: true,
        username: action.payload.username,
        remember: action.payload.remember,
        token: action.payload.token
      }

    case "LOGOUT":
      localStorage.clear()
      return {
        ...state,
        isAuthenticated: false,
        username: null,
        remember: false
      }

    default:
      return state;
  }
}

const checkLogin = () => {
  //get local storage
  const token = localStorage.getItem('token')
  const username = localStorage.getItem('username')
  if (token === null) {
    return false;
  } else {
    if (username !== process.env.REACT_APP_USERNAME) {
      return false
    } else {
      return true
    }
  }
}



const Router = () => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {!checkLogin() ? <Login /> :
        <Routers>
          <Topbar />
          <Sidebar />
          <Routes>
            <Route exact path="/" element={<Dashboard />} />
            <Route path="/production" element={<Produksi />} />
            <Route path="/hsse" element={<Hsse />} />
            <Route path="/finance" element={<Finance />} />
            <Route path="/plant_performance" element={<PlantPerform />} />
            <Route path="/field_location" element={<FieldLocation />} />
            <Route path="/sub_surface" element={<SubSurface />} />
            <Route path="/login" element={state.isAuthenticated ? <Navigate to="/" /> : <Login />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Routers>
      }
    </AuthContext.Provider>
  );
};

export default Router;
